// src/components/LoginComponent.js
import React from 'react';
import { Box, Button, Grid, Typography, Paper } from '@mui/material';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google'; // Import Material-UI Google icon
import { ToastContainer, toast } from 'react-toastify';
import '../css/styles.css';
const LoginComponent = () => {
  const clientId = '1025142309416-pon5siunqku3hvfv6ukbbes0ks6ieepe.apps.googleusercontent.com';
 
  const handleLoginSuccess = async (tokenId) => {
    const response1 = await fetch('https://broadwayoriginals.com/apis/loginwith/login_with_google.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: tokenId 
      }),
    });
    const resdata = await response1.json();
    if (response1.ok) {
      const headers = response1.headers;
      const customlog = headers.get('Login'); 
      const expiresAt = new Date(Date.now() + 3600000).toISOString();
      localStorage.setItem('user', JSON.stringify({ ...resdata, expiresAt, customlog }));
      window.location.href = "/dashboard";
    }else{
      toast.error(resdata.message);
    }
};

  const onGoogleSuccess = (response) => {
    handleLoginSuccess(response.credential);
  };

  const onGoogleError = () => {
    console.log('Google Login Failed');
  };


  return (
    <GoogleOAuthProvider clientId={clientId}>
        <Grid container spacing={2}>
        <ToastContainer />
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{width: '100%'}}>
              <GoogleLogin
                onSuccess={onGoogleSuccess}
                onError={onGoogleError}
                render={(renderProps) => (
                  <Button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    fullWidth
                    sx={{
                      minWidth: '100%',
                      height: 'auto',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1,
                      backgroundColor: '#4285F4', // Google color
                      color: '#fff',
                      fontWeight: 'bold',
                      '&:hover': {
                        backgroundColor: '#357ae8', // Darker shade on hover
                      },
                      transition: 'background-color 0.3s, transform 0.3s',
                    }}
                  >
                    <GoogleIcon sx={{ marginRight: 1 }} />
                    Login with Google
                  </Button>
                )}
              />
            </Box>
          </Grid>
        </Grid>
    </GoogleOAuthProvider>
  );
};

export default LoginComponent;
