import React from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Badge,
  Avatar,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';

function HomePost({ title, image, newsData, sectionUrl }) {
  const handleExploreClick = () => {
    window.location = sectionUrl;
  };

  return (
    <>
      {/* Section Header */}
      <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
        <Grid item xs={12} md={9}>
          <Typography
            variant="h4"
            sx={{
              color: "#00a8a9",
              display: 'flex',
              alignItems: 'center',
              fontSize: { xs: '24px', sm: '28px', md: '32px' },
              fontWeight: 'bold',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Box
              component="img"
              src={image}
              alt=""
              sx={{
                width: { xs: "8%", sm: "6%", md: "4%" },
                marginRight: "5px",
              }}
            />
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
          <Badge
            sx={{
              fontSize: "18px",
              background: "none",
              color: "#00a8a9",
              borderRadius: "20px",
              padding: "8px 16px",
              cursor: "pointer",
              border: '1px solid #00a8a9',
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#00a8a9",
                color: "#ffffff",
                transform: "scale(1.05)",
              },
            }}
            onClick={handleExploreClick}
          >
            <span style={{ fontWeight: 200 }}>
              Explore More 
              <FontAwesomeIcon 
                icon={faChevronRight} 
                style={{ fontSize: "15px", paddingLeft: "30px" }} 
              />
            </span>
          </Badge>
        </Grid>
      </Grid>

      {/* News Grid */}
      <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
        {/* Featured Article Block */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          {newsData.map((item, index) => (
            index === 0 && (
              <Card
                key={item.id}
                sx={{
                  border: "none",
                  borderRadius: "20px",
                  mb: 0,
                  background: "transparent",
                  boxShadow: 'none',
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  transition: "transform 0.3s ease",
                  '&:hover': {
                    transform: "scale(1.03)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={`https://broadwayoriginals.com/${item.post_img}`}
                  loading="lazy"
                  sx={{
                    borderRadius: "20px",
                    height: { xs: '240px', md: '357px' },
                    objectFit: 'cover',
                  }}
                />
                <CardContent sx={{ mt: 1, p: 0 }}>
                  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${item.author.img}`}
                      alt={item.author.name}
                      sx={{ width: 30, height: 30, mr: 1 }}
                    />
                    <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                  </Typography>
                  <Typography
                    variant="h6"
                    component={NavLink}
                    to={item.custom_url}
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontWeight: 'bold',
                      marginTop: '8px',
                      display: 'block',
                      overflow: 'hidden',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      width: '100%',
                      maxHeight: '100%',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      fontSize: '25px'
                    }}
                  >
                    {item.post_title}
                  </Typography>
                </CardContent>
              </Card>
            )
          ))}
        </Grid>

        {/* List of Articles Block */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          {newsData.map((item, index) => (
            index !== 0 && (
              <Card
                key={item.id}
                sx={{
                  maxWidth: '100%',
                  display: 'flex',
                  mb: 2,
                  borderRadius: '20px',
                  flexDirection: 'row',
                  background: "transparent",
                  boxShadow: 'none',
                  transition: "transform 0.3s ease",
                  '&:hover': {
                    transform: "scale(1.03)",
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={`https://broadwayoriginals.com/${item.post_img}`}
                  alt={item.post_title}
                  sx={{
                    width: { xs: '120px', sm: '151px' },
                    height: 'auto',
                    borderRadius: '20px',
                  }}
                />
                <CardContent sx={{ flex: '1 1 auto', padding: 1, width: '100% !important' }}>
                  <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar
                      src={`https://broadwayoriginals.com/${item.author.img}`}
                      alt={item.author.name}
                      sx={{ width: 30, height: 30, mr: 1 }}
                    />
                    <span style={{ color: "rgb(50, 19, 19)" }}>{item.author.name}</span>
                  </Typography>
                  <Typography
                    variant="h6"
                    component={NavLink}
                    to={item.custom_url}
                    sx={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontWeight: 'bold',
                      marginTop: '8px',
                      display: 'block',
                      overflow: 'hidden',
                      whiteSpace: 'normal',
                      wordBreak: 'break-word',
                      width: '100%',
                      maxHeight: '100%',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                    }}
                  >
                    {item.post_title}
                  </Typography>
                </CardContent>
              </Card>
            )
          ))}
        </Grid>
      </Grid>
      
      {/* Mobile-Only Explore Button */}
      <Grid container justifyContent="flex-end" sx={{ mt: 2, display: { xs: 'flex', md: 'none' } }}>
        <Grid item xs={12} sx={{ padding: 0 }}>
        <Badge
  sx={{
    fontSize: "18px",
    background: "none",
    color: "#00a8a9",
    borderRadius: "20px",
    padding: "10px",
    cursor: "pointer",
    border: "1px solid #00a8a9",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    mb: 2
  }}
  onClick={handleExploreClick}
>
  <span style={{ fontWeight: 200 ,}}>Explore More</span>
  <FontAwesomeIcon icon={faChevronRight}   />
</Badge>

        </Grid>
      </Grid>
    </>
  );
}

export default HomePost;
