import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Badge,
  Box,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import Slider from "react-slick"; // Import react-slick
import food_icon from "../images/food.png";
import '../css/styles.css'; // Import your styles

function Food({ foodlist }) {
  const data_list = JSON.parse(foodlist);

  const onclickOpenFood = () => {
    window.location = "/food";
  };

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      {data_list.length > 0 && (
        <>
          {/* Header Section */}
          <Grid container spacing={2} sx={{ mb: 0, mt: 3 }}>
            <Grid item xs={12} md={9}>
              <Typography
                variant="h4"
                sx={{
                  color: "#00a8a9",
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: { xs: '24px', sm: '28px', md: '32px' },
                  fontWeight: 'bold',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <Box
                  component="img"
                  src={food_icon}
                  alt=""
                  sx={{
                    width: { xs: "8%", sm: "6%", md: "4%" },
                    marginRight: "5px",
                  }}
                />
                Food
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "none",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "8px 16px",
                  cursor: "pointer",
                  border: '1px solid #00a8a9',
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#00a8a9",
                    color: "#ffffff",
                    transform: "scale(1.05)",
                  },
                }}
                onClick={onclickOpenFood}
              >
                <span style={{ fontWeight: 200 }}>
                  Explore More 
                  <FontAwesomeIcon 
                    icon={faChevronRight} 
                    style={{ fontSize: "15px", paddingLeft: "30px" }} 
                  />
                </span>
              </Badge>
            </Grid>
        </Grid>

          {/* Main Content Section for Desktop */}
          <Grid container spacing={2} sx={{ marginTop: "20px", display: { xs: 'none', md: 'flex' } }}>
            {data_list.map((item, index) => (
              <Grid item xs={12} md={3} key={index}>
                <Card sx={{ borderRadius: '10px', backgroundColor: 'dark', color: 'white', height: '100%', position: 'relative', transition: "transform 0.3s ease",
                  '&:hover': {
                    transform: "scale(1.03)",
                  }, }}>
                  <CardMedia
                    component="img"
                    image={`https://broadwayoriginals.com/${item.img}`}
                    alt={item.name}
                    sx={{ height: '100%',  objectFit: 'cover' }}
                    loading="lazy"
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      backgroundColor: 'none', // Semi-transparent background for better visibility
                      padding: 1,
                    }}
                  >
                    <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.7rem' }}>
                        {item.name}
                      </Typography>
                    </NavLink>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Carousel for Mobile */}
          <Grid className="hide-on-desktop" sx={{ mt: 5, mb: 5 }}>
              <Slider
                {...settings}
                slidesToShow={1}
                slidesToScroll={1}
                arrows={false}
                centerMode={false} // Disable centerMode if enabled, as it can introduce spacing
                infinite={true}
                swipeToSlide={true}
                focusOnSelect={true}
                // Adding custom styling to remove space between slides
                style={{
                  margin: 0,
                }}
              >
                {data_list.map((item, index) => (
                  <Box key={index} sx={{ padding: 0, margin: 0 }}>
                    <Card sx={{ borderRadius: '10px', backgroundColor: 'dark', color: 'white', height: '100%' }}>
                      <CardMedia
                        component="img"
                        image={`https://broadwayoriginals.com/${item.img}`}
                        alt={item.name}
                        sx={{ height: '100%', objectFit: 'cover' }}
                        loading="lazy"
                      />
                      <Box sx={{ position: 'absolute', bottom: 0, padding: 3 }}>
                        <NavLink to={`/food/${item.url}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                          <Typography variant="h6" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.7rem' }}>
                            {item.name}
                          </Typography>
                        </NavLink>
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Slider>
          </Grid>

          {/* Mobile View "Explore More" Button */}
          <Grid container justifyContent="flex-end" sx={{ marginTop: 1, display: { xs: 'flex', md: 'none' } }}>
            <Grid item xs={12}>
              <Badge
                sx={{
                  fontSize: "18px",
                  background: "none",
                  color: "#00a8a9",
                  borderRadius: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  border: "1px solid #00a8a9",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
                onClick={onclickOpenFood}
              >
                <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
                <FontAwesomeIcon icon={faChevronRight} />
              </Badge>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Food;
