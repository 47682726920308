import * as React from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Container from '@mui/joy/Container';
import Badge from '@mui/joy/Container';
import Grid from '@mui/joy/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import travelicon from "../images/travel.png";
import travel_img1 from '../images/travel/travel-1.png';
import travel_img2 from '../images/travel/travel-2.png';
import travel_img3 from '../images/travel/travel-3.png';
import travel_img4 from '../images/travel/travel-4.png';

export default function Travel() {
  const sectionUrl = '/travel';
  const handleExploreClick = (travel_category_url) => {
    window.location = travel_category_url;
  };
  

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 2, mt: 3 }}>
        <Grid item xs={12} md={9}>
          <Typography
            variant="h4"
            sx={{
              color: "#00a8a9",
              display: 'flex',
              alignItems: 'center',
              fontSize: { xs: '24px', sm: '28px', md: '32px' },
              fontWeight: 'bold',
              textAlign: { xs: 'center', md: 'left' },
             
            }}
          >
            <Box
              component="img"
              src={travelicon}
              alt=""
              sx={{
                width: { xs: "8%", sm: "6%", md: "auto" },
                marginRight: "5px",
              }}
            />
            Travel
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
        <Badge
            sx={{
              fontSize: "18px",
              background: "none",
              color: "#00a8a9",
              borderRadius: "20px",
              padding: "8px 16px",
              cursor: "pointer",
              border: '1px solid #00a8a9',
              width: 'fit-content !important',
              mr: 0,
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#00a8a9",
                color: "#ffffff",
                transform: "scale(1.05)",
              },
            }}
            onClick={() => handleExploreClick('/travel')}
          >
            <span style={{ fontWeight: 200 }}>
              Explore More 
              <FontAwesomeIcon 
                icon={faChevronRight} 
                style={{ fontSize: "15px", paddingLeft: "30px", mr: 0 }} 
              />
            </span>
          </Badge>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        {[{ img: travel_img1, title: 'Religious Tourism', custom_url: 'religious-tourism' }, { img: travel_img2, title: 'Budget Travel', custom_url: 'budget-travel' }, { img: travel_img3, title: 'Solo Travel', custom_url: 'solo-travel' }, { img: travel_img4, title: 'Adventure Tourism', custom_url: 'adventure-tourism' }]
          .map((item, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                component="li"
                sx={{
                  minWidth: 300,
                  flexGrow: 1,
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardCover>
                  <img src={item.img} loading="lazy" alt="" />
                </CardCover>
                <CardContent>
                  <Typography
                    level="body-lg"
                    textColor="#fff"
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 'lg',
                      fontFamily: 'Roboto',
                      mt: { xs: 12, sm: 18 },
                      fontSize: {
                        xs: '1.5rem',
                        sm: '2rem',
                        md: '2rem',
                        lg: '2rem',
                      },
                    }}
                    onClick={() => handleExploreClick('/travel/'+item.custom_url)}
                  >
                    {item.title}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </>
  );
}
