import React, { useEffect } from 'react';

function MetaTags({ tags }) {
  useEffect(() => {
    // Remove all existing meta tags except 'viewport' and 'theme-color'
    const allMetaTags = document.querySelectorAll('meta');
    allMetaTags.forEach(tag => {
      const nameAttr = tag.getAttribute('name');
      const propertyAttr = tag.getAttribute('property');
      if (nameAttr !== 'viewport' && nameAttr !== 'theme-color' && !propertyAttr?.startsWith('og:')) {
        tag.parentNode.removeChild(tag);
      }
    });

    // Create new meta tags based on the provided data
    tags.forEach(tag => {
      const newMetaTag = document.createElement('meta');
      if (tag.tag_name.startsWith('og:')) {
        newMetaTag.setAttribute('property', tag.tag_name); // Use 'property' for Open Graph tags
      } else {
        newMetaTag.setAttribute('name', tag.tag_name);
      }
      newMetaTag.setAttribute('content', tag.tag_content);
      document.head.appendChild(newMetaTag);
    });

    // Clean up: Remove all added meta tags when component unmounts or tags change
    return () => {
      const addedMetaTags = document.querySelectorAll('meta[name="keywords"], meta[name="description"], meta[property^="og:"]');
      addedMetaTags.forEach(tag => tag.parentNode.removeChild(tag));
    };
  }, [tags]);  // Effect runs when 'tags' change

  return null;  // This component doesn't render anything
}

export default MetaTags;
