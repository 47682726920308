import React from 'react';
import {
  Box,
  Container,
  Grid,
  Card,
  CardMedia,
  Typography,
  Badge,
  Avatar,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTape } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import entertainmenticon from "../images/Entertainment.svg";
import movie_img from "../images/movies_img.png";
import korean_img from "../images/korean.png";
import tv_series from "../images/tv_series.png";
import lasttest from "../images/latest_trailerss.png";
import '../css/styles.css'; // Import your styles

function Entertainment() {
  const onclickOpenEntertainment = () => {
    window.location = "/entertainment";
  };

  // Detect screen size
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Show on mobile only

  return (
    <>
      <Grid container spacing={2} sx={{ mb: 0 }}>
        <Grid item xs={12} md={9}>
          <Typography
            variant="h4"
            sx={{
              color: "#00a8a9",
              display: 'flex',
              alignItems: 'center',
              fontSize: { xs: '24px', sm: '28px', md: '32px' },
              fontWeight: 'bold',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <Box
              component="img"
              src={entertainmenticon}
              alt=""
              sx={{
                width: { xs: "8%", sm: "6%", md: "4%" },
                marginRight: "5px",
              }}
            />
            Entertainment
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} sx={{ textAlign: 'end', display: { xs: 'none', md: 'block' } }}>
          <Badge
            sx={{
              fontSize: "18px",
              background: "none",
              color: "#00a8a9",
              borderRadius: "20px",
              padding: "8px 16px",
              cursor: "pointer",
              border: '1px solid #00a8a9',
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#00a8a9",
                color: "#ffffff",
                transform: "scale(1.05)",
              },
            }}
            onClick={onclickOpenEntertainment}
          >
            <span style={{ fontWeight: 200 }}>
              Explore More 
              <FontAwesomeIcon 
                icon={faChevronRight} 
                style={{ fontSize: "15px", paddingLeft: "30px" }}
              />
            </span>
          </Badge>
        </Grid>
      </Grid>  

      {/* Content Section with Equal Heights */}
      <Grid container spacing={4} sx={{ marginTop: 0, alignItems: 'stretch' }}>
        {/* Left Side - Main Content */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', pt: 0 }}>
          <Card sx={{ borderRadius: "21px", border: "none", height: '100%', position: 'relative' }}>
            <CardMedia
              component="img"
              image={movie_img}
              alt="Movies"
              sx={{ borderRadius: "21px", height: "100%", objectFit: 'cover' }}
            />
            {/* Title at the Bottom */}
            <Box sx={{
              position: 'absolute', bottom: 0, left: 0, right: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "21px", padding: 2
            }}>
              <NavLink to="/entertainment/movies-reviews" style={{ textDecoration: 'none', color: 'inherit' }}>
              <Typography
                  variant="h5"
                  sx={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: {
                      xs: '1.5rem',  
                      sm: '2rem',   
                      md: '2rem',   
                      lg: '2.2rem',  
                    }
                  }}
                >
                  Movies
                </Typography>

              </NavLink>
            </Box>
          </Card>
        </Grid>

        {/* Right Side - Other Sections */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Grid container direction="column" spacing={2} sx={{ flexGrow: 1 }}>
          <Grid item sx={{ flexGrow: 1 }}>
            <Card sx={{ borderRadius: "21px", border: "none", height: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                image={korean_img}
                alt="Korean"
                sx={{ borderRadius: "21px", height: "100%", objectFit: 'cover' }}
              />
              {/* Left-aligned and Vertically Centered Title */}
              <Box sx={{
                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center', // Vertically center the text
                backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "21px", padding: 2
              }}>
                <NavLink to="/entertainment/korean-reviews" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: {
                      xs: '1.5rem',  
                      sm: '2rem',   
                      md: '2rem',   
                      lg: '2.2rem',  
                    } }}>
                    Korean
                  </Typography>
                </NavLink>
              </Box>
            </Card>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }}>
            <Card sx={{ borderRadius: "21px", border: "none", height: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                image={tv_series}
                alt="TV Series"
                sx={{ borderRadius: "21px", height: "100%", objectFit: 'cover' }}
              />
              {/* Left-aligned and Vertically Centered Title */}
              <Box sx={{
                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center', // Vertically center the text
                backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "21px", padding: 2
              }}>
                <NavLink to="/entertainment/tv-series-reviews" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="h5" sx={{ color: 'white', fontWeight: 'bold', fontSize: {
                      xs: '1.5rem',  
                      sm: '2rem',   
                      md: '2rem',   
                      lg: '2.2rem',  
                    } }}>
                    TV Series
                  </Typography>
                </NavLink>
              </Box>
            </Card>
          </Grid>

          <Grid item sx={{ flexGrow: 1 }}>
            <Card sx={{ borderRadius: "21px", border: "none", height: '100%', position: 'relative' }}>
              <CardMedia
                component="img"
                image={lasttest}
                alt="Latest Trailers"
                sx={{ borderRadius: "21px", height: "100%", objectFit: 'cover' }}
              />
              {/* Left-aligned and Vertically Centered Title */}
              <Box sx={{
                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                display: 'flex', justifyContent: 'flex-start', alignItems: 'center', // Vertically center the text
                backgroundColor: "rgba(0, 0, 0, 0.5)", borderRadius: "21px", padding: 2
              }}>
                <NavLink to="/entertainment/Animation-movies-reviews" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="h5" sx={{  color: 'white', fontWeight: 'bold', fontSize: {
                      xs: '1.5rem',  
                      sm: '2rem',   
                      md: '2rem',   
                      lg: '2.2rem',  
                    } }}>
                    Animation
                  </Typography>
                </NavLink>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      </Grid>

      {/* Mobile View "Explore More" Button */}
      {isMobile && (
        <Grid container justifyContent="flex-end" sx={{ marginTop: 4 }}>
          <Grid item xs={12}>
            <Badge
              sx={{
                fontSize: "18px",
                background: "none",
                color: "#00a8a9",
                borderRadius: "20px",
                padding: "10px",
                cursor: "pointer",
                border: "1px solid #00a8a9",
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
              }}
              onClick={onclickOpenEntertainment}
            >
              <span style={{ color: "#00a8a9", fontWeight: 200 }}>Explore More</span>
              <FontAwesomeIcon icon={faChevronRight} />
            </Badge>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Entertainment;
