import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  Tooltip,
  Stack,
  LinearProgress,
  Box,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowBack,
  ArrowForward,
  Share as ShareIcon,
  Facebook,
  Twitter,
  WhatsApp,
  LinkedIn,
  Link as LinkIcon,
} from "@mui/icons-material";

const DynamicGallery = ({ newpagecontent, post_title }) => {
  const [open, setOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [currentGalleryImages, setCurrentGalleryImages] = useState([]);
  const containerRef = useRef(null);

  const handleOpen = (galleryImages, imageIndex) => {
    setCurrentGalleryImages(galleryImages);
    setCurrentImageIndex(imageIndex);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImageIndex(null);
    setCurrentGalleryImages([]);
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % currentGalleryImages.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + currentGalleryImages.length) % currentGalleryImages.length);
  };

  const handleShare = (platform, post_title) => {
    const url = currentGalleryImages[currentImageIndex];
    const encodedTitle = encodeURIComponent(post_title);
    const encodedUrl = encodeURIComponent(url);

    switch (platform) {
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedTitle}`, "_blank");
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`, "_blank");
        break;
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodedTitle} ${encodedUrl}`, "_blank");
        break;
      case "linkedin":
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedTitle}`, "_blank");
        break;
      case "copy":
        navigator.clipboard.writeText(url);
        alert("Image URL copied to clipboard!");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const galleries = Array.from(container.querySelectorAll(".bdo-custom-gallery"));
      galleries.forEach((gallery) => {
        const galleryImages = Array.from(gallery.querySelectorAll("img")).map((img) => img.src);
        gallery.addEventListener("click", (e) => {
          e.preventDefault();
          const target = e.target.closest("img");
          if (target) {
            const imageIndex = galleryImages.indexOf(target.src);
            if (imageIndex !== -1) {
              handleOpen(galleryImages, imageIndex);
            }
          }
        });
      });
    }

    return () => {
      const container = containerRef.current;
      if (container) {
        const galleries = Array.from(container.querySelectorAll(".bdo-custom-gallery"));
        galleries.forEach((gallery) => {
          gallery.removeEventListener("click", () => {});
        });
      }
    };
  }, [newpagecontent]);

  return (
    <>
      <div
        ref={containerRef}
        className="dynamic-gallery-container"
        dangerouslySetInnerHTML={{ __html: newpagecontent }}
      />

      {open && currentImageIndex !== null && (
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="xl"
          fullWidth
          PaperProps={{
            style: {
              background: "rgba(0, 0, 0, 0.9)",
              backdropFilter: "blur(10px)",
              borderRadius: "8px",
              padding: 0,
              width: "100%",
              height: "100vh",
            },
          }}
        >
          <DialogContent
            style={{
              position: "relative",
              padding: 0,
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={currentGalleryImages[currentImageIndex]}
                alt={`Gallery Image ${currentImageIndex + 1}`}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                  borderRadius: "8px",
                  transition: "opacity 0.3s ease",
                }}
              />
            </Box>

            <LinearProgress
              variant="determinate"
              value={((currentImageIndex + 1) / currentGalleryImages.length) * 100}
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "white",
                },
              }}
            />

            <Stack
              direction="row"
              spacing={1}
              sx={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                background: "rgba(0, 0, 0, 0.6)",
                padding: "8px 16px",
                borderRadius: "16px",
              }}
            >
              <Tooltip title="Share on Facebook">
                <IconButton onClick={() => handleShare("facebook", post_title)} color="primary">
                  <Facebook />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on Twitter">
                <IconButton onClick={() => handleShare("twitter", post_title)} sx={{ color: "#1DA1F2" }}>
                  <Twitter />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on WhatsApp">
                <IconButton onClick={() => handleShare("whatsapp", post_title)} sx={{ color: "#25D366" }}>
                  <WhatsApp />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share on LinkedIn">
                <IconButton onClick={() => handleShare("linkedin", post_title)} sx={{ color: "#0077b5" }}>
                  <LinkedIn />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy Link">
                <IconButton onClick={() => handleShare("copy", post_title)} sx={{ color: "white" }}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            <IconButton
              onClick={handlePrevious}
              sx={{
                position: "absolute",
                top: "50%",
                left: "16px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
                color: "white",
              }}
            >
              <ArrowBack />
            </IconButton>

            <IconButton
              onClick={handleNext}
              sx={{
                position: "absolute",
                top: "50%",
                right: "16px",
                transform: "translateY(-50%)",
                backgroundColor: "rgba(255, 255, 255, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.5)",
                },
                color: "white",
              }}
            >
              <ArrowForward />
            </IconButton>
          </DialogContent>

          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              },
              color: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
      )}
    </>
  );
};

export default DynamicGallery;
